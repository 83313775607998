import { useCurrentUser } from 'FrontRoyalAngular';
import { useTranslation } from 'react-i18next';
import { useAppHeaderViewModel } from 'Navigation';
import { Container } from 'Container';
import { Chat } from '../Chat/Chat';

export function BotPage() {
    const currentUser = useCurrentUser()!;
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { t } = useTranslation('back_royal');

    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(t('chat.chat.ai_advisor').toLocaleUpperCase());

    if (!currentUser) return null;

    return (
        <div className="tw-relative tw-h-full tw-w-full tw-overflow-hidden tw-bg-gradient-to-bl tw-from-white tw-to-[#F7F7F7] tw-pt-[1px]">
            <Container className="tw-relative tw-h-full">
                <Chat
                    currentUser={currentUser}
                    clientContext={{ uiContext: 'bot_page' }}
                    endConversationOnClose
                    enableSetNewTopic
                />
            </Container>
        </div>
    );
}
